import { Address, isAddress } from "viem";
import { SerializedTokenType } from "@/app/features/swap/slice";

export function formatBigIntCompact(number: string): string {
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    compactDisplay: "short",
  }).format(Number(number));
}

export function shortenEthAddress(address: string): string {
  const prefixLength = 6; // Number of characters to keep at the start
  const suffixLength = 4; // Number of characters to keep at the end
  if (!isAddress(address)) {
    return address;
  }
  return `${address.slice(0, prefixLength)}...${address.slice(-suffixLength)}`;
}

const alwaysTrue = () => true;
export function createTokenFilterFunction(
  search: string,
): (token: SerializedTokenType) => boolean {
  const isValidAddress = isAddress(search);

  if (isValidAddress) {
    return (t: SerializedTokenType) =>
      search.toLowerCase() === t.address.toLowerCase();
  }

  const lowerSearchParts = search
    .toLowerCase()
    .split(/\s+/)
    .filter((s) => s.length > 0);

  if (lowerSearchParts.length === 0) return alwaysTrue;

  const matchesSearch = (s: string): boolean => {
    const sParts = s
      .toLowerCase()
      .split(/\s+/)
      .filter((s) => s.length > 0);

    return lowerSearchParts.every(
      (p) =>
        p.length === 0 ||
        sParts.some((sp) => sp.startsWith(p) || sp.endsWith(p)),
    );
  };

  return ({ name, symbol }: SerializedTokenType): boolean =>
    Boolean((symbol && matchesSearch(symbol)) || (name && matchesSearch(name)));
}

export function filterTokens(
  tokens: SerializedTokenType[],
  search: string,
): SerializedTokenType[] {
  return tokens.filter(createTokenFilterFunction(search));
}

export function balanceComparator(balanceA?: bigint, balanceB?: bigint) {
  if (balanceA && balanceB) {
    if (balanceA === balanceB) return 0;
    return balanceA > balanceB ? -1 : 1;
  } else if (balanceA && balanceA > 0) {
    return -1;
  } else if (balanceB && balanceB > 0) {
    return 1;
  }
  return 0;
}
export function tokenComparator(balances?: Record<Address, string>) {
  const balancesMap = new Map<Address, string>(
    Object.entries(balances || {}) as [Address, string][],
  );

  return (tokenA: SerializedTokenType, tokenB: SerializedTokenType) => {
    const balanceComp = balanceComparator(
      BigInt(balancesMap?.get(tokenA.address) || 0n),
      BigInt(balancesMap?.get(tokenB.address) || 0n),
    );
    if (balanceComp !== 0) {
      return balanceComp;
    }
    if (tokenA.symbol && tokenB.symbol) {
      return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1;
    } else {
      return tokenA.symbol ? -1 : tokenB.symbol ? -1 : 0;
    }
  };
}

export function getSortedTokensByQuery(
  tokens: SerializedTokenType[] | undefined,
  searchQuery: string,
) {
  if (!tokens) return [];
  if (!searchQuery) return tokens;

  const searchQueryLower = searchQuery.toLowerCase().trim();

  const exactMatches: SerializedTokenType[] = [];
  const matchSubstrings: SerializedTokenType[] = [];
  const rest: SerializedTokenType[] = [];

  tokens.forEach((token) => {
    const symbolLower = token.symbol?.toLowerCase();

    if (symbolLower === searchQueryLower) {
      exactMatches.push(token);
    } else if (symbolLower?.startsWith(searchQueryLower)) {
      matchSubstrings.push(token);
    } else {
      rest.push(token);
    }
  });

  return [...exactMatches, ...matchSubstrings, ...rest];
}
