import { SerializedTokenType } from "@/app/features/swap/slice";
import { Address } from "viem";

export const ONEINCH_NATIVE_ADDRESS: Address =
  "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee";
export const NATIVES: Record<number, SerializedTokenType> = {
  1: {
    address: ONEINCH_NATIVE_ADDRESS,
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    logoURI: "./eth.svg",
    chainId: 1,
  },
  11_155_111: {
    address: ONEINCH_NATIVE_ADDRESS,
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    logoURI: "./eth.svg",
    chainId: 11_155_111,
  },
  8453: {
    address: ONEINCH_NATIVE_ADDRESS,
    decimals: 18,
    symbol: "ETH",
    name: "Ethereum",
    logoURI: "./base.svg",
    chainId: 8453,
  },
  56: {
    address: ONEINCH_NATIVE_ADDRESS,
    decimals: 18,
    symbol: "BNB",
    name: "Binance Coin",
    logoURI: "./bnb.svg",
    chainId: 56,
  },
  42161: {
    symbol: "ETH",
    name: "Ether",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    decimals: 18,
    logoURI:
      "https://tokens.1inch.io/0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee.png",
    chainId: 42161,
  },
  137: {
    symbol: "MATIC",
    name: "MATIC",
    address: "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee",
    decimals: 18,
    logoURI:
      "https://tokens.1inch.io/0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0.png",
    chainId: 137,
  },
};

export const ONEINCH_ROUTER_ADDRESS: Record<number, Address> = {
  1: "0x1111111254eeb25477b68fb85ed929f73a960582",
  56: "0x1111111254eeb25477b68fb85ed929f73a960582",
  8453: "0x1111111254eeb25477b68fb85ed929f73a960582",
  42161: "0x1111111254eeb25477b68fb85ed929f73a960582",
  137: "0x1111111254eeb25477b68fb85ed929f73a960582",
};

export const DEFAULT_TOKEN: Record<number, SerializedTokenType> = {
  1: {
    address: "0xe9a97b0798b1649045c1d7114f8c432846828404",
    decimals: 18,
    symbol: "FROGE",
    name: "Froge",
    logoURI: "./froge.png",
    chainId: 1,
  },
};

export const DEFAULT_FAVOURITE_TOKENS: Record<number, SerializedTokenType[]> = {
  [1]: [NATIVES[1], DEFAULT_TOKEN[1]],
  [56]: [NATIVES[56]],
  [8453]: [NATIVES[8453]],
  [42161]: [NATIVES[42161]],
  [137]: [NATIVES[137]],
};

export const OMNICAT_TOKENS: Record<number, SerializedTokenType> = {
  1: {
    chainId: 1,
    address: "0x9e20461bc2c4c980f62f1B279D71734207a6A356",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
  56: {
    chainId: 56,
    address: "0x9e20461bc2c4c980f62f1B279D71734207a6A356",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
  8453: {
    chainId: 8453,
    address: "0xc48e605c7b722a57277e087a6170b9e227e5ac0a",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
  137: {
    chainId: 137,
    address: "0x9e20461bc2c4c980f62f1B279D71734207a6A356",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
  42161: {
    chainId: 42161,
    address: "0x9e20461bc2c4c980f62f1B279D71734207a6A356",
    name: "OmniCat",
    symbol: "OMNI",
    decimals: 18,
    logoURI: "/omni.png",
  },
};

export const FROGE_TOKEN = DEFAULT_TOKEN[1];
