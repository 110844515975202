import { Address } from "viem";
import { arbitrum, base, bsc, mainnet, polygon } from "wagmi/chains";

export const SAFE_WALLET_ADDRESS: Record<number, Address> = {
  [mainnet.id]: "0x4d1E64484F31B91B22627F7888AC9587E6D86072",
  [bsc.id]: "0xBe13A1Be125E807eEe62c311D97aC3F144bc55bE",
  [base.id]: "0x512A1F071311D27608Ff7465B9b0633C9B7B6817",
  [polygon.id]: "0x03129cB1Afc1fCD73E0b3509b53FBC7d4E7B6A32",
  [arbitrum.id]: "0xF972f86B4108F1C8F8b0E214ab4c0546FFBD6814",
};
