import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { useAppSelector } from "@/app/hooks";
import { Address } from "viem";

export type SerializedTokenType = {
  address: Address;
  decimals: number;
  symbol: string;
  name: string;
  logoURI: string;
  chainId: number;
};

export enum SwapStep {
  SWAP = "SWAP",
  CONFIRM = "CONFIRM",
  SELECT_INPUT_TOKEN = "SELECT_INPUT_TOKEN",
  SELECT_OUTPUT_TOKEN = "SELECT_OUTPUT_TOKEN",
  CUSTOM_OUTPUT_TOKEN = "CUSTOM_OUTPUT_TOKEN",
  CUSTOM_INPUT_TOKEN = "CUSTOM_INPUT_TOKEN",
  SETTINGS = "SETTINGS",
  CUSTOM_TOKENS = "CUSTOM_TOKENS",
  TRANSACTION = "TRANSACTION",
}

interface SwapState {
  inputToken?: SerializedTokenType;
  outputToken?: SerializedTokenType;
  inputAmount?: string;
  outputAmount?: string;
  deadline: number;
  slippage: number;
  swapStep: SwapStep;
}

type AmountSet = {
  amount: string;
};

const initialState: SwapState = {
  deadline: 30,
  slippage: 0.1,
  swapStep: SwapStep.SWAP,
};

const swapSlice = createSlice({
  name: "swap",
  initialState,
  reducers: {
    switchNetworkState(state) {
      state.swapStep = SwapStep.SWAP;
      state.inputAmount = undefined;
    },
    resetSettings(state) {
      state.slippage = initialState.slippage;
    },
    resetSwapState() {
      return initialState;
    },
    setInputToken(
      state,
      action: PayloadAction<SerializedTokenType | undefined>,
    ) {
      state.inputToken = action.payload;
    },
    setOutputToken(
      state,
      action: PayloadAction<SerializedTokenType | undefined>,
    ) {
      state.outputToken = action.payload;
    },
    setInputAmount(state, action: PayloadAction<AmountSet>) {
      state.inputAmount = action.payload.amount;
    },
    setOutputAmount(state, action: PayloadAction<AmountSet>) {
      state.outputAmount = action.payload.amount;
    },
    setSwapStep(state, action: PayloadAction<SwapStep>) {
      state.swapStep = action.payload;
    },
    setSlippage(state, action: PayloadAction<number | undefined>) {
      console.log("setSlippage", action.payload);
      state.slippage =
        typeof action.payload === "undefined" || isNaN(action.payload)
          ? initialState.slippage
          : action.payload;
    },
    setDeadline(state, action: PayloadAction<number>) {
      state.deadline = action.payload;
    },
    swapTokenDirection(state) {
      const inputToken = state.inputToken;
      const inputAmount = state.inputAmount;
      state.inputToken = state.outputToken;
      state.inputAmount = state.outputAmount;
      state.outputToken = inputToken;
      state.outputAmount = inputAmount;
    },
  },
});

export const {
  setInputToken,
  setOutputToken,
  setOutputAmount,
  setInputAmount,
  setSwapStep,
  swapTokenDirection,
  setSlippage,
  setDeadline,
  resetSwapState,
  switchNetworkState,
  resetSettings,
} = swapSlice.actions;

export const useInputToken = () =>
  useAppSelector((state) => state.swap.inputToken);
export const useOutputToken = () =>
  useAppSelector((state) => state.swap.outputToken);

export const useInputAmount = () =>
  useAppSelector((state) => state.swap.inputAmount);

export const useSwapStep = () => useAppSelector((state) => state.swap.swapStep);

export const useSlippage = () => useAppSelector((state) => state.swap.slippage);
export default swapSlice.reducer;
